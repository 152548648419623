import CheckIcon from '@mui/icons-material/Check';
import { Link, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ActionMenu from 'core/components/ActionMenu';
import StyledDataGrid, { DataGridColumnHeader } from 'core/components/DataGrid';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import dayjs, { Dayjs } from 'dayjs';
import { setIn, useFormik } from 'formik';
import DocumentsService from 'modules/irp/modules/supplements/api/DocumentsService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import RemoveVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/RemoveVehicleDialog';
import EditVehiclesDialog from 'modules/irp/modules/supplements/modules/add_vehicle/components/EditVehicleDialog';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, {
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleNew,
	getFeeCalculationDate,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { DateValidationSchema, dayjsToDate } from 'types/Date';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

const DataGrid = StyledDataGrid<Vehicle>();

const StyledButton = styled(Button)({ minWidth: 150 });

function NoRowsOverlay() {
	const { t } = useTranslation('irp/supplements/add_vehicle');
	return (
		<Box display="flex" justifyContent="center" alignItems="center" height="100%">
			<Typography variant="subtitle1">{t('details.no_rows_label')}</Typography>
		</Box>
	);
}

export default function AddDetailsStep() {
	// Hooks
	const { t } = useTranslation(['irp/supplements/add_vehicle', 'irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();
	const theme = useTheme();
	const { supplementKey } = useTypedParams(AddVehiclePaths);
	const supplementsService = useAPI(SupplementsService);
	const documentsService = useAPI(DocumentsService);
	const vehiclesService = useAPI(VehiclesService);
	const { supplement } = useSupplement();

	// Validation
	const vehicleUpdateSchema = Yup.object().shape({
		documents: Yup.object({
			form2290: Yup.object(LookupValueValidationSchema).nullable(),
			title: Yup.object(LookupValueValidationSchema).nullable(),
			other: Yup.object(LookupValueValidationSchema).nullable(),
		}).optional(),
		registration: Yup.object({
			feeCalculationDate: Yup.object().shape(LookupValueValidationSchema),
			leaseDate: DateValidationSchema.when('feeCalculationDate.code', {
				is: VehicleFeeCalculationDate.Lease,
				then: (s) => s.required(t('data.validation.required', { ns: 'core' })),
				otherwise: (s) => s.strip(),
			}),
			otherDate: DateValidationSchema.when('feeCalculationDate.code', {
				is: VehicleFeeCalculationDate.Other,
				then: (s) => s.required(t('data.validation.required', { ns: 'core' })),
				otherwise: (s) => s.strip(),
			}),
			firstOperatedDate: DateValidationSchema.when('feeCalculationDate.code', {
				is: VehicleFeeCalculationDate.FirstOperated,
				then: (s) => s.required(t('data.validation.required', { ns: 'core' })),
				otherwise: (s) => s.strip(),
			}),
		}),
		purchase: Yup.object().when('registration.feeCalculationDate.code', ([feeCalcCode], s) => {
			if (feeCalcCode !== VehicleFeeCalculationDate.Purchase) return s.strip();
			return s.shape({
				date: DateValidationSchema.required(t('data.validation.required', { ns: 'core' })),
			});
		}),
	});

	// Form
	const { values, setValues, ...formik } = useFormik<Vehicle[]>({
		initialValues: [],
		validationSchema: Yup.array().min(1).of(vehicleUpdateSchema),
		onSubmit: () => navigate(AddVehiclePaths.Verify.buildPath({ supplementKey })),
	});

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [documentStatuses, setDocumentStatuses] = useState<LookupValue[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [rowsSaving, setRowsSaving] = useState<Record<string, boolean>>({});
	const [removeDialogVehicle, setRemoveDialogVehicle] = useState<Vehicle | null>(null);
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);

	// Computed
	const isSaving = Object.values(rowsSaving).find((v) => v);
	const selectedVehicles = values.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const getVehicles = useCallback(() => {
		return supplementsService.listVehicles(supplementKey).then((vehiclesResp) => {
			setValues(vehiclesResp);
		});
	}, [supplementKey, setValues, supplementsService]);

	const loadVehicles = async () => {
		setTableLoading(true);
		await getVehicles();
		setTableLoading(false);
	};

	useEffect(() => {
		Promise.all([
			documentsService.getDocumentStatuses().then((resp) => setDocumentStatuses(resp)),
			getVehicles(),
		]).finally(() => setLoading(false));
	}, [supplementKey, documentsService, getVehicles]);

	const getDocStatusByCode = (code: string) => documentStatuses?.find((s) => s.code === code);

	const updateVehicle = async (vehicleKey: string, field: string, value: unknown) => {
		const idx = values.findIndex((vehicle) => vehicle.key === vehicleKey);
		if (idx === -1) return;

		const errors = await formik.setFieldValue(`[${idx}].${field}`, value, true);
		const data: typeof values = setIn(values, `[${idx}].${field}`, value);

		// Invalid, skip
		if (errors && errors[idx] && Object.keys(errors[idx] || {}).length > 0) return;

		// Run after the formik state has been updated
		const vehicle = values[idx];
		try {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: true });

			const finalData = { ...data[idx], final: true };
			const updateFields = vehicleUpdateSchema.cast(finalData, {
				context: finalData,
				// Final cast to strip unknown fields, this is required to satisfy API minimum requirements
				stripUnknown: true,
			});
			if (!updateFields) return;

			await supplementsService.updateVehicle(supplementKey, vehicle.key, updateFields as VehicleFields);
		} catch (e) {
			// Revert changes
			loadVehicles();
		} finally {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: false });
		}
	};

	const handleNext = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'add-details-form-errors',
				message: t('errors.vehicle.ready', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		// All purchase dates must share the same month and year
		const allDatesValid = values.every((val) => {
			const firstDate = getFeeCalculationDate(values[0]);
			const vehicleDate = getFeeCalculationDate(val);

			return vehicleDate?.month === firstDate?.month && vehicleDate?.year === firstDate?.year;
		});

		if (!allDatesValid) {
			return openToast({
				id: 'supplements/add-vehicle/all-dates',
				message: t('vehicles.errors.all_dates', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		// Dates outside fleet reg year
		const fleetStartDate = dayjs(supplement?.fleet?.startDate);
		const fleetEndDate = dayjs(supplement?.fleet?.endDate);
		const hasDatesOutsideRegYear = !!values.find((val) => {
			const d = dayjs(getFeeCalculationDate(val));
			return d.isBefore(fleetStartDate) || d.isAfter(fleetEndDate);
		});
		if (hasDatesOutsideRegYear) {
			return openToast({
				id: 'supplements/add-vehicle/dates-outside-reg-year',
				message: t('vehicles.toasts.feeCalcWithinFleetReg', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleRemoveVehicleConfirm = async () => {
		if (!removeDialogVehicle) return undefined;

		return vehiclesService.remove(removeDialogVehicle).then(() => {
			loadVehicles();
			setRemoveDialogVehicle(null);

			openToast({
				id: `remove-vehicle-dialog:${removeDialogVehicle.key}`,
				message: t('dialogs.remove_vehicle.removed', { ns: 'irp/supplements' }),
				severity: 'success',
			});
		});
	};

	let minDate = dayjsFromObject(supplement?.fleet?.startDate);
	let maxDate = dayjsFromObject(supplement?.fleet?.endDate);

	// If reg period hasn't started, allow only the first month
	if (minDate?.isAfter(dayjs())) {
		minDate = dayjsFromObject(supplement?.fleet?.startDate)?.startOf('month');
		maxDate = dayjsFromObject(supplement?.fleet?.startDate)?.endOf('month');
	}

	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 180, flex: 1 },
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 90,
			flex: 1,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'title.number',
			minWidth: 90,
			flex: 1,
			valueGetter: ({ row }) => row.title.number,
		},
		{
			headerName: t('vehicle.registration.feeCalculationDate', { ns: 'data' }),
			field: 'registration.feeCalculationDate',
			minWidth: 180,
			flex: 1,
			valueGetter: ({ row }) => {
				const date = getFeeCalculationDate(row);
				return date ? dayjsFromObject(date) : null;
			},
			renderCell: ({ value, row }) => {
				const feeCalculationDate = row.registration?.feeCalculationDate?.code || '';

				return (
					<DatePicker
						value={value}
						onChange={(v: Dayjs | null) => {
							if (!v || !v.isValid() || !row.purchase) return;
							const date = dayjsToDate(v);

							switch (feeCalculationDate) {
								case VehicleFeeCalculationDate.Purchase:
									updateVehicle(row.key, 'purchase.date', date);
									break;
								case VehicleFeeCalculationDate.FirstOperated:
									updateVehicle(row.key, 'registration.firstOperatedDate', date);
									break;
								case VehicleFeeCalculationDate.Lease:
									updateVehicle(row.key, 'registration.leaseDate', date);
									break;
								case VehicleFeeCalculationDate.Other:
									updateVehicle(row.key, 'registration.otherDate', date);
									break;
								default:
									break;
							}
						}}
						slotProps={{
							textField: {
								variant: 'standard',
							},
						}}
						minDate={minDate}
						maxDate={maxDate}
					/>
				);
			},
		},
		{
			renderHeader: () => (
				<DataGridColumnHeader
					label={t('vehicle.documents.form2290.title', { ns: 'data' })}
					tooltip={
						<>
							{t('vehicle.documents.form2290.tooltip', { ns: 'data' })}
							<br />
							<Link
								color={theme.palette.background.default}
								target="_blank"
								href={t('vehicle.documents.form2290.link', { ns: 'data' })}
							>
								{t('vehicle.documents.form2290.link', { ns: 'data' })}
							</Link>
						</>
					}
				/>
			),
			field: 'documents.form2290',
			description: t('vehicle.documents.form2290.tooltip', { ns: 'data' }),
			minWidth: 145,
			flex: 1,
			valueGetter: ({ row }) => row.documents?.form2290?.displayName,
			renderCell: ({ row }) => (
				<FormControl fullWidth>
					<Select
						variant="standard"
						value={row.documents?.form2290?.code || ''}
						onChange={(event: SelectChangeEvent<string>) => {
							if (!row.documents) return;

							updateVehicle(row.key, 'documents.form2290', getDocStatusByCode(event.target.value));
						}}
					>
						{documentStatuses?.map((status) => (
							<MenuItem key={status.id} value={status.code}>
								{status.displayName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			),
		},
		{
			headerName: t('vehicle.documents.title.title', { ns: 'data' }),
			field: 'documents.title',
			minWidth: 140,
			flex: 1,
			valueGetter: ({ row }) => row.documents?.title?.displayName,
			renderCell: ({ row }) => (
				<FormControl fullWidth>
					<Select
						variant="standard"
						value={row.documents?.title?.code || ''}
						onChange={(event: SelectChangeEvent<string>) => {
							if (!row.documents) return;

							updateVehicle(row.key, 'documents.title', getDocStatusByCode(event.target.value));
						}}
					>
						{documentStatuses?.map((status) => (
							<MenuItem key={status.id} value={status.code}>
								{status.displayName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			),
		},
		{
			headerName: t('vehicle.documents.other.title', { ns: 'data' }),
			field: 'documents.other',
			minWidth: 140,
			flex: 1,
			valueGetter: ({ row }) => row.documents?.other?.displayName,
			renderCell: ({ row }) => (
				<FormControl fullWidth>
					<Select
						variant="standard"
						value={row.documents?.other?.code || ''}
						onChange={(event: SelectChangeEvent<string>) => {
							if (!row.documents) return;

							updateVehicle(row.key, 'documents.other', getDocStatusByCode(event.target.value));
						}}
					>
						{documentStatuses?.map((status) => (
							<MenuItem key={status.id} value={status.code}>
								{status.displayName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			),
		},
		{
			headerName: t('data.ready', { ns: 'core' }),
			field: 'ready',
			headerAlign: 'center',
			align: 'center',
			width: 70,
			valueGetter: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);
				return !formik.errors[index];
			},
			renderCell: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);

				// Loading state
				if (rowsSaving[row.key]) return <CircularProgress size={24} />;

				// Valid state
				if (!formik.errors[index]) return <CheckIcon color="success" />;

				// Error state
				return <span>&mdash;</span>;
			},
		},
		{
			headerName: t('data.actions', { ns: 'core' }),
			field: 'action',
			headerAlign: 'center',
			sortable: false,
			align: 'center',
			width: 70,
			renderCell: ({ row }) => (
				<ActionMenu
					options={[
						{ id: 'edit', label: t('buttons.edit', { ns: 'core' }) },
						{ id: 'remove', label: t('buttons.remove', { ns: 'core' }) },
					]}
					onClick={({ id }) => {
						switch (id) {
							case 'edit':
								navigate(AddVehiclePaths.Vehicle.Info.buildPath({ supplementKey, vehicleKey: row.key }));
								break;
							case 'remove':
								setRemoveDialogVehicle(row);
								break;
							default:
								break;
						}
					}}
				/>
			),
		},
	];

	if (loading) return SupplementContentSkeleton;

	return (
		<>
			<form name="addDetailsForm" noValidate>
				<Box display="flex" flexDirection="column" rowGap={2}>
					<Card>
						<CardContent>
							<Typography variant="h3">{t('details.title')}</Typography>
							<Box mt={4} mb={2}>
								<DataGrid
									className="striped"
									columns={columns}
									rows={values}
									loading={tableLoading}
									slots={{ noRowsOverlay: NoRowsOverlay }}
									checkboxSelection
									rowSelectionModel={selectedVehicleIds}
									onRowSelectionModelChange={setSelectedVehicleIds}
									disableRowSelectionOnClick
								/>
							</Box>
							<Box display="flex" columnGap={1}>
								<StyledButton
									variant="outlined"
									onClick={() => navigate(AddVehiclePaths.Vehicle.buildPath({ supplementKey, vehicleKey: VehicleNew }))}
								>
									{t('dialogs.add_vehicle.title', { ns: 'irp/supplements' })}
								</StyledButton>
								{selectedVehicleIds.length > 0 && (
									<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
										{t('irp/supplements:dialogs.edit_vehicle.title', { count: selectedVehicleIds.length })}
									</StyledButton>
								)}
							</Box>
						</CardContent>
					</Card>
					<SupplementStepFooter
						nextLabel={t('buttons.next', { ns: 'core' })}
						nextDisabled={isSaving}
						onNext={handleNext}
					/>
				</Box>
			</form>

			<EditVehiclesDialog
				vehicles={selectedVehicles}
				documentStatuses={documentStatuses}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>

			<RemoveVehicleDialog
				vehicle={removeDialogVehicle}
				isOpen={!!removeDialogVehicle}
				setIsOpen={() => {
					setRemoveDialogVehicle(null);
				}}
				onConfirm={handleRemoveVehicleConfirm}
			/>
		</>
	);
}
