import { createFilterOptions } from '@mui/material';

export interface State {
	id: number;
	name: string;
	code: string;
	countryId: number;
}

export interface StateFields {
	id: number;
}

export const statesWithDivider = (states: State[]) =>
	states.reduce((acc: (State & { divider?: boolean })[], s) => {
		// Changing country, add divider
		if ((acc[acc.length - 1] as State)?.countryId !== s.countryId && acc[acc.length - 1]) {
			acc.push({ ...s, id: -1 * s.countryId, code: `divider-${s.countryId}`, divider: true });
		}

		acc.push(s);
		return acc;
	}, []);

export const stateCountyRequired = (state: string) => {
	return ['IA'].includes(state.toUpperCase());
};

export const stateFilterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: (o: State) => `${o.code} - ${o.name}`,
});
