enum Program {
	IFTA = 'PROGRAM_IFTA',
	IRP = 'PROGRAM_IRP',
}

export default Program;

export enum IftaStatus {
	Active = 'A',
}
