import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import BigStepIcon from 'core/components/BigStepIcon';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export interface SupplementStepProps {
	steps: { label: string; route: string; activeRoutes?: string[] }[];
}

export default function SupplementSteps({ steps }: SupplementStepProps) {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const activeStep = steps.findIndex(
		({ route, activeRoutes }) =>
			matchPath(route, pathname) ||
			(activeRoutes && activeRoutes.some((activeRoute) => matchPath(activeRoute, pathname))),
	);

	// TODO mobile friendly steps
	return (
		<Stepper orientation="horizontal" activeStep={activeStep}>
			{steps.map(({ label, route }) => (
				<Step key={label}>
					<StepButton onClick={() => navigate(route)}>
						<StepLabel StepIconComponent={BigStepIcon}>{label}</StepLabel>
					</StepButton>
				</Step>
			))}
		</Stepper>
	);
}
