import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CertifyCheckbox from 'core/components/CertifyCheckbox';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SupplementSubmitFormFields } from 'types/Supplement';

export interface TermsCheckboxProps {
	formik: ReturnType<typeof useFormik<SupplementSubmitFormFields>>;
	verbiage: string;
}
export default function TermsCheckbox({ formik, verbiage }: TermsCheckboxProps) {
	const { t } = useTranslation('irp/supplements');

	return (
		<Box>
			<Typography variant="subtitle1" fontWeight="bold" gutterBottom>
				{t('submit.terms.title')}
			</Typography>
			<Typography variant="body2" mb={3}>
				{verbiage}
			</Typography>

			<CertifyCheckbox
				name="termsCertified"
				checked={formik.values.termsCertified}
				onChange={formik.handleChange}
				error={!!formik.errors.termsCertified && formik.submitCount > 0}
			/>
		</Box>
	);
}
