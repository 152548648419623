import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI, useToast } from 'core/hooks';
import { getIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import EditVehiclePaths from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/paths';
import Vehicle, { VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

export type EditVehiclesForm = {
	form2290: LookupValue | null;
	title: LookupValue | null;
	other: LookupValue | null;
};

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	vehicles: Vehicle[];
	onVehiclesEdited: () => void;
	documentStatuses: LookupValue[];
}

export default function EditVehiclesDialog({
	vehicles,
	isOpen,
	setIsOpen,
	onVehiclesEdited,
	documentStatuses,
}: EditVehiclesDialogProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements/edit_vehicle', 'irp/supplements']);
	const { openToast } = useToast();
	const supplementsService = useAPI(SupplementsService);
	const { supplementKey } = useTypedParams(EditVehiclePaths);

	// State
	const [isSaving, setIsSaving] = useState<boolean>(false);

	// Form validation
	const validationSchema = Yup.object()
		.shape({
			form2290: Yup.object().shape(LookupValueValidationSchema).nullable(),
			title: Yup.object().shape(LookupValueValidationSchema).nullable(),
			other: Yup.object().shape(LookupValueValidationSchema).nullable(),
		})
		.test('required', (data, context) => {
			// At least one field is required
			if (data.form2290 || data.title || data.other) return true;

			return context.createError({
				path: 'atLeastOneField',
			});
		});

	// Form
	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			form2290: null,
			title: null,
			other: null,
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			const updatedVehicles = vehicles.map((v) => {
				const fields: VehicleFields = {};

				// Set document statuses
				fields.documents = {};
				if (data.form2290) fields.documents.form2290 = data.form2290;
				if (data.title) fields.documents.title = data.title;
				if (data.other) fields.documents.other = data.other;

				return supplementsService.updateVehicle(supplementKey, v.key, fields);
			});

			return Promise.all(updatedVehicles)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/edit_vehicle/edit/incomplete`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	// Computed
	type documentKey = keyof Pick<EditVehiclesForm, 'form2290' | 'title' | 'other'>;
	const documents: documentKey[] = ['form2290', 'title', 'other'];

	return (
		<Dialog
			title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading', { ns: 'irp/supplements' })}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					{documents.map((field) => (
						<Grid item xs={4} key={field}>
							<FormSelectField
								name={field}
								label={t(`vehicle.documents.${field}.title`, { ns: 'data' })}
								helperText={(formik.errors as Record<documentKey, string>)[field]}
								value={documentStatuses.find((x) => x.id === values[field]?.id) || null}
								options={documentStatuses}
								onChange={(v) => setFieldValue(field, v)}
								getOptionKey={(v) => v.id}
								getOptionLabel={(v) => v.displayName}
								slotProps={{
									formControl: {
										error:
											!!(formik.errors as Record<documentKey, string>)[field] ||
											!!getIn(formik.errors, 'atLeastOneField'),
									},
									autocomplete: {
										disabled: isSaving,
									},
								}}
							/>
						</Grid>
					))}
				</Grid>
			</form>
		</Dialog>
	);
}
