import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import ActionMenu from 'core/components/ActionMenu';
import StyledDataGrid from 'core/components/DataGrid';
import { useAPI, useToast } from 'core/hooks';
import DocumentsService from 'modules/irp/modules/supplements/api/DocumentsService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import AddVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/AddVehicleDialog';
import RemoveVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/RemoveVehicleDialog';
import EditVehicleDialog from 'modules/irp/modules/supplements/modules/edit_vehicle/components/EditVehicleDialog';
import EditVehiclePaths from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/paths';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, { vehicleHasChanged } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import LookupValue from 'types/LookupValue';

const StyledButton = styled(Button)({ minWidth: 150 });
const DataGrid = StyledDataGrid<Vehicle>();

export default function EditDetailsStep() {
	const { t } = useTranslation(['irp/supplements/edit_vehicle', 'irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();

	// Params
	const { supplementKey } = useTypedParams(EditVehiclePaths.Details);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [vehicles, setVehicles] = useState<Vehicle[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [documentStatuses, setDocumentStatuses] = useState<LookupValue[]>([]);
	const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState<boolean>(false);
	const [removeDialogVehicle, setRemoveDialogVehicle] = useState<Vehicle | null>(null);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);

	// Services
	const supplementsService = useAPI(SupplementsService);
	const vehiclesService = useAPI(VehiclesService);
	const documentsService = useAPI(DocumentsService);

	// Computed
	const selectedVehicles = vehicles.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const getVehicles = useCallback(() => {
		return supplementsService.listVehicles(supplementKey, { clonedFrom: true, weightGroup: true }).then(setVehicles);
	}, [supplementKey, supplementsService]);

	const loadVehicles = async () => {
		setTableLoading(true);
		await getVehicles();
		setTableLoading(false);
	};

	useEffect(() => {
		Promise.all([getVehicles(), documentsService.getDocumentStatuses().then(setDocumentStatuses)]).finally(() =>
			setLoading(false),
		);
	}, [getVehicles, documentsService]);

	const handleNext = () => {
		const isValid = vehicles.every((vehicle) => {
			if (!vehicle.clonedFrom) return false;
			return vehicleHasChanged(vehicle.clonedFrom, vehicle);
		});

		if (!isValid) {
			return openToast({
				id: 'edit-details-form-errors',
				message: t('errors.vehicle.ready', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return navigate(EditVehiclePaths.Verify.buildPath({ supplementKey }));
	};

	const handleRemoveVehicleConfirm = async () => {
		if (!removeDialogVehicle) return undefined;

		return vehiclesService.remove(removeDialogVehicle).then(() => {
			loadVehicles();
			setRemoveDialogVehicle(null);

			openToast({
				id: `remove-vehicle-dialog:${removeDialogVehicle.key}`,
				message: t('dialogs.remove_vehicle.removed', { ns: 'irp/supplements' }),
				severity: 'success',
			});
		});
	};

	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 200, flex: 1 },
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 50,
			flex: 1,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'title.number',
			minWidth: 125,
			flex: 1,
			valueGetter: ({ row }) => row.title.number,
		},
		{
			headerName: t('vehicle.documents.form2290.title', { ns: 'data' }),
			field: 'documents.form2290',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ row }) => row?.documents?.form2290?.displayName,
		},
		{
			headerName: t('vehicle.documents.title.title', { ns: 'data' }),
			field: 'documents.title',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ row }) => row.documents?.title?.displayName,
		},
		{
			headerName: t('vehicle.documents.other.title', { ns: 'data' }),
			field: 'documents.other',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ row }) => row?.documents?.other?.displayName,
		},
		{
			headerName: t('data.ready', { ns: 'core' }),
			field: 'ready',
			headerAlign: 'center',
			align: 'center',
			width: 65,

			renderCell: ({ row }) => {
				if (!row.clonedFrom) return <span>&mdash;</span>;

				if (vehicleHasChanged(row.clonedFrom, row)) {
					return <CheckIcon color="success" />;
				}

				return <span>&mdash;</span>;
			},
		},
		{
			headerName: t('data.actions', { ns: 'core' }),
			field: 'action',
			headerAlign: 'center',
			sortable: false,
			align: 'center',
			width: 70,
			renderCell: ({ row }) => {
				const isReady = row.clonedFrom && vehicleHasChanged(row.clonedFrom, row);
				const options = [
					{ id: 'edit', label: t('buttons.edit', { ns: 'core' }) },

					// If is ready add view option to actions list.
					...(isReady ? [{ id: 'view', label: t('buttons.view', { ns: 'core' }) }] : []),

					{ id: 'remove', label: t('buttons.remove', { ns: 'core' }) },
				];

				return (
					<ActionMenu
						options={options}
						onClick={({ id }) => {
							switch (id) {
								case 'edit':
									navigate(EditVehiclePaths.Vehicle.Edit.Info.buildPath({ supplementKey, vehicleKey: row.key }));
									break;
								case 'view':
									navigate(EditVehiclePaths.Vehicle.View.buildPath({ supplementKey, vehicleKey: row.key }));
									break;
								case 'remove':
									setRemoveDialogVehicle(row);
									break;
								default:
									break;
							}
						}}
					/>
				);
			},
		},
	];

	if (loading) return SupplementContentSkeleton;

	return (
		<>
			<Box display="flex" flexDirection="column" rowGap={2}>
				<Card>
					<CardContent>
						<Typography variant="h3">{t('title', { ns: 'irp/vehicles' })}</Typography>
						<Box mt={4} mb={2}>
							<DataGrid
								className="striped"
								columns={columns}
								rows={vehicles}
								loading={tableLoading}
								disableRowSelectionOnClick
								checkboxSelection
								rowSelectionModel={selectedVehicleIds}
								onRowSelectionModelChange={setSelectedVehicleIds}
							/>
						</Box>
						<Box display="flex" columnGap={1}>
							<StyledButton variant="outlined" onClick={() => setAddVehicleDialogOpen(true)}>
								{t('dialogs.add_vehicle.title', { ns: 'irp/supplements' })}
							</StyledButton>
							{selectedVehicleIds.length > 0 && (
								<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
									{t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: selectedVehicleIds.length })}
								</StyledButton>
							)}
						</Box>
					</CardContent>
				</Card>
				<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
			</Box>

			<AddVehicleDialog
				supplementKey={supplementKey}
				isOpen={addVehicleDialogOpen}
				setIsOpen={setAddVehicleDialogOpen}
				onVehiclesAdded={() => {
					setAddVehicleDialogOpen(false);
					loadVehicles();
				}}
			/>

			<EditVehicleDialog
				vehicles={selectedVehicles}
				documentStatuses={documentStatuses}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>

			<RemoveVehicleDialog
				vehicle={removeDialogVehicle}
				isOpen={!!removeDialogVehicle}
				setIsOpen={() => {
					setRemoveDialogVehicle(null);
				}}
				onConfirm={handleRemoveVehicleConfirm}
			/>
		</>
	);
}
