import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'core/components/Dialog';
import FormDateField from 'core/components/FormDateField';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import { getIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { VehicleTransfer } from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/TransferDetailsStep';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import Vehicle, { VehicleFeeCalculationDate, VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidationSchema, DateValidations } from 'types/Date';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

export type EditVehiclesForm = {
	deactivateDate: Date | null;
	deactivateReason: LookupValue | null;
	feeCalculationDate: Date | null;
	form2290: LookupValue | null;
	title: LookupValue | null;
	other: LookupValue | null;
};

interface EditVehiclesDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen'> {
	vehicles: VehicleTransfer[];
	onVehiclesEdited: () => void;
	deactivateReasons: LookupValue[];
	documentStatuses: LookupValue[];
}

export default function EditVehicleDialog({
	vehicles,
	isOpen,
	setIsOpen,
	onVehiclesEdited,
	deactivateReasons,
	documentStatuses,
}: EditVehiclesDialogProps) {
	// Hooks
	const { t } = useTranslation('irp/supplements/transfer_vehicle');
	const { openToast } = useToast();
	const { supplementKey } = useTypedParams(TransferVehiclePaths);

	// Services
	const supplementsService = useAPI(SupplementsService);

	// State
	const [isSaving, setIsSaving] = useState<boolean>(false);

	// Form validation
	const validationSchema = Yup.object()
		.shape({
			deactivateDate: DateValidationSchema.default(null)
				.test('minDate', DateValidations.minDate(t))
				.test('futureDate', DateValidations.noFutureDate(t))
				.nullable(),
			deactivateReason: Yup.object().shape(LookupValueValidationSchema).nullable(),
			feeCalculationDate: DateValidationSchema.default(null)
				.test('minDate', DateValidations.minDate(t))
				.test('futureDate', DateValidations.noFutureDate(t))
				.nullable(),
			form2290: Yup.object().shape(LookupValueValidationSchema).nullable(),
			title: Yup.object().shape(LookupValueValidationSchema).nullable(),
			other: Yup.object().shape(LookupValueValidationSchema).nullable(),
		})
		.test('required', (data, context) => {
			// At least one field is required
			if (
				data.deactivateDate ||
				data.deactivateReason ||
				data.feeCalculationDate ||
				data.form2290 ||
				data.title ||
				data.other
			)
				return true;

			return context.createError({
				path: 'atLeastOneField',
			});
		});

	// Form
	const { values, setFieldValue, ...formik } = useFormik<EditVehiclesForm>({
		initialValues: {
			deactivateDate: null,
			deactivateReason: null,
			feeCalculationDate: null,
			form2290: null,
			title: null,
			other: null,
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			const vehicleUpdateRequests = vehicles.reduce((requests: Promise<Vehicle>[], vehicle: VehicleTransfer) => {
				// Update deleted vehicle
				const deletedVehicleFields: VehicleFields = {};
				deletedVehicleFields.deactivate = {};

				if (data.deactivateDate) deletedVehicleFields.deactivate.date = data.deactivateDate;
				if (data.deactivateReason) deletedVehicleFields.deactivate.reason = data.deactivateReason;

				if (data.deactivateDate || data.deactivateReason)
					requests.push(supplementsService.updateVehicle(supplementKey, vehicle.key, deletedVehicleFields));

				if (!vehicle.transferVehicle || !vehicle.transferVehicle.registration) return requests;

				const { feeCalculationDate } = vehicle.transferVehicle.registration;

				let transferVehicleFields: VehicleFields = {};
				// Set fee calculation date
				switch (feeCalculationDate?.code) {
					case VehicleFeeCalculationDate.Purchase:
						transferVehicleFields = {
							purchase: {
								date: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.FirstOperated:
						transferVehicleFields = {
							registration: {
								firstOperatedDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Lease:
						transferVehicleFields = {
							registration: {
								leaseDate: data.feeCalculationDate,
							},
						};
						break;
					case VehicleFeeCalculationDate.Other:
						transferVehicleFields = {
							registration: {
								otherDate: data.feeCalculationDate,
							},
						};
						break;
					default:
						break;
				}

				// Set document statuses
				transferVehicleFields.documents = {};
				if (data.form2290) transferVehicleFields.documents.form2290 = data.form2290;
				if (data.title) transferVehicleFields.documents.title = data.title;
				if (data.other) transferVehicleFields.documents.other = data.other;

				if (data.feeCalculationDate || data.form2290 || data.other || data.title)
					requests.push(
						supplementsService.updateVehicle(supplementKey, vehicle.transferVehicle.key, transferVehicleFields),
					);

				return requests;
			}, []);

			return Promise.all(vehicleUpdateRequests)
				.then(() => {
					formik.resetForm();
					onVehiclesEdited();
				})
				.finally(() => setIsSaving(false));
		},
	});

	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/transfer_vehicle/edit/incomplete`,
				message: t('dialogs.edit_vehicle.error', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleClose = () => {
		if (setIsOpen) setIsOpen(false);
		// Wait for dialog close
		setTimeout(() => formik.resetForm(), 250);
	};

	// Computed
	type documentKey = keyof Pick<EditVehiclesForm, 'form2290' | 'title' | 'other'>;
	const documents: documentKey[] = ['form2290', 'title', 'other'];

	return (
		<Dialog
			title={t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: vehicles.length })}
			isOpen={isOpen}
			onConfirm={handleSubmit}
			confirmLabel={t('buttons.save', { ns: 'core' })}
			setIsOpen={handleClose}
			maxWidth="sm"
		>
			<Typography paragraph>{t('dialogs.edit_vehicle.subheading')}</Typography>
			<form name="bulkEditVehicleForm" noValidate>
				<Grid container pt={1} spacing={2}>
					<Grid item xs={4}>
						<FormDateField
							name="deactivateDate"
							label={t('vehicle.deactivateDate', { ns: 'data' })}
							helperText={formik.errors.deactivateDate}
							slotProps={{
								formControl: {
									error: !!formik.errors.deactivateDate || !!getIn(formik.errors, 'atLeastOneField'),
									disabled: isSaving,
								},
								datePicker: {
									disableFuture: true,
									disabled: isSaving,
									value: dayjsFromObject(values.deactivateDate),
									onChange: (v) => {
										const year = v?.year();
										const month = (v?.month() || 0) + 1;
										const day = v?.date();

										setFieldValue('deactivateDate', year && month && day ? { year, month, day } : null);
									},
									slotProps: {
										field: {
											clearable: false,
										},
									},
								},
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<FormSelectField
							name="deactivateReason"
							label={t('vehicle.deactivateReason', { ns: 'data' })}
							helperText={formik.errors.deactivateReason}
							value={deactivateReasons.find((x) => x.id === values.deactivateReason?.id) || null}
							options={deactivateReasons}
							onChange={(v) => setFieldValue('deactivateReason', v)}
							getOptionKey={(v) => v.id}
							getOptionLabel={(v) => v.displayName}
							slotProps={{
								formControl: { error: !!getIn(formik.errors, 'atLeastOneField') },
								autocomplete: {
									disabled: isSaving,
								},
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<FormDateField
							name="feeCalculationDate"
							label={t('vehicle.registration.feeCalculationDate', { ns: 'data' })}
							helperText={formik.errors.feeCalculationDate}
							slotProps={{
								formControl: {
									error: !!formik.errors.feeCalculationDate || !!getIn(formik.errors, 'atLeastOneField'),
									disabled: isSaving,
								},
								datePicker: {
									value: dayjsFromObject(values.feeCalculationDate),
									disableFuture: true,
									disabled: isSaving,
									onChange: (v) => {
										const year = v?.year();
										const month = (v?.month() || 0) + 1;
										const day = v?.date();

										setFieldValue('feeCalculationDate', year && month && day ? { year, month, day } : null);
									},
									slotProps: {
										field: {
											clearable: false,
										},
									},
								},
							}}
						/>
					</Grid>
					{documents.map((field) => (
						<Grid item xs={4} key={field}>
							<FormSelectField
								name={field}
								label={t(`vehicle.documents.${field}.title`, { ns: 'data' })}
								helperText={(formik.errors as Record<documentKey, string>)[field]}
								value={documentStatuses.find((x) => x.id === values[field]?.id) || null}
								options={documentStatuses}
								onChange={(v) => setFieldValue(field, v)}
								getOptionKey={(v) => v.id}
								getOptionLabel={(v) => v.displayName}
								slotProps={{
									formControl: {
										error:
											!!(formik.errors as Record<documentKey, string>)[field] ||
											!!getIn(formik.errors, 'atLeastOneField'),
									},
									autocomplete: {
										disabled: isSaving,
									},
								}}
							/>
						</Grid>
					))}
				</Grid>
			</form>
		</Dialog>
	);
}
