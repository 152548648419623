import AddFleetPaths from 'modules/irp/modules/supplements/modules/add_fleet/routes/paths';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import EditFleetPaths from 'modules/irp/modules/supplements/modules/edit_fleet/routes/paths';
import EditVehiclePaths from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/paths';
import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import RenewalPaths from 'modules/irp/modules/supplements/modules/renewal/routes/paths';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import WeightGroupChangePaths from 'modules/irp/modules/supplements/modules/weight_group_change/routes/paths';
import { route } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';

export interface WithSupplementKey {
	supplementKey: string;
}

const SupplementPaths = route(
	'irp/supplements',
	{},
	{
		Supplement: route(':supplementKey'),
	},
);

export default SupplementPaths;

// Redirect based on supplement type
export const SupplementTypePaths = {
	// Vehicle supplements
	[SupplementType.AddVehicle]: AddVehiclePaths,
	[SupplementType.TransferVehicle]: TransferVehiclePaths,
	[SupplementType.EditVehicle]: EditVehiclePaths,
	[SupplementType.DeleteVehicle]: DeleteVehiclePaths,

	// Weight group change
	[SupplementType.WeightGroupChange]: WeightGroupChangePaths,

	// Fleet supplements
	[SupplementType.AddFleet]: AddFleetPaths,
	[SupplementType.EditFleet]: EditFleetPaths,

	// New account
	[SupplementType.NewAccount]: NewAccountPaths,

	// Renewal
	[SupplementType.Renewal]: RenewalPaths,
};
