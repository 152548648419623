import SupplementVerifyForm from 'modules/irp/modules/supplements/components/SupplementVerifyForm';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';

export default function DeleteVehiclesVerifyStep() {
	return (
		<form name="addVehicleForm" noValidate>
			<SupplementVerifyForm
				previousPath={DeleteVehiclePaths.Details}
				nextPath={DeleteVehiclePaths.Submit}
				feeGridHeaders={['refund', 'plateFee']}
				noCredentials
			/>
		</form>
	);
}
